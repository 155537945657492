<template>
  <div>
    <a-row>
      <a-col :span="14">
        <a-descriptions
          :column="1"
          class="custom-descriptions custom-descriptions-max-10"
        >
          <a-descriptions-item label="套餐名称">{{ data.package_name }}</a-descriptions-item>
        </a-descriptions>
      </a-col>
      <a-col :span="2" :offset="1">
        <span>共{{ data.item_count }}项</span>
      </a-col>
      <a-col :span="4" :offset="1">
        <span
          style="color:green;text-align: end"
        >金额:{{ data.package_price_display }}元</span>
      </a-col>
    </a-row>
    <a-table
      :columns="columns"
      :data-source="items"
      :pagination="false"
      @change="fetchData"
      row-key="id"
      :scroll="{ y: 350 }"
    />
    <a-space style="margin-top: 50px;">
      <span>告知书状态：{{ data.noticeStatus }}</span>
      <a v-if="data.signaturePic !== ''" @click="showImg(data.signaturePic)" style="margin-left: 200px;">点击查看</a>
      <span v-if="data.signaturePic === ''" style="margin-left: 200px;">暂无签名图片</span>
      <!--      <a @click="print" style="margin-left: 10px;" :loading="loadingPDF">打印家属告知书</a>-->
      <a-button type="primary" :loading="loadingPDF" @click="print">打印家属告知书</a-button>
    </a-space>
    <preview-image
      :visible.sync="previewVisible"
      :image.sync="previewImage"
    />
  </div>
</template>

<script>
import { findOrderPackages, printFamilyNoticePdf } from '@/api/order'
import PreviewImage from '@/components/PreviewImage'
import printJS from 'print-js'
export default {
  name: 'Packages',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  components: { PreviewImage },
  data() {
    return {
      data: {},
      previewVisible: false,
      previewImage: '',
      items: [],
      loadingPDF: false
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '序号',
          dataIndex: 'id'
        },
        {
          title: '项目名称',
          dataIndex: 'name'
        },
        {
          title: '类型',
          dataIndex: 'item_type'
        },
        {
          title: '计价单位',
          dataIndex: 'unit'
        },
        {
          title: '计价个数',
          dataIndex: 'count'
        },
        {
          title: '价格（元）',
          dataIndex: 'price_display'
        },
        {
          title: '项目介绍',
          dataIndex: 'remark'
        }
      ]
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    // 显示图片预览
    showImg(url) {
      this.previewVisible = true
      this.previewImage = url
    },
    fetchData() {
      findOrderPackages(this.id).then(res => {
        if (res.code === 0) {
          this.data = res.data
          this.items = res.data.item_list
        }
      })
    },
    print() {
      this.$info({
        title: '正在生成家属告知书PDF文件，请耐心等候',
        content: ''
      })
      if (this.loadingPDF) {
        return
      }

      this.loadingPDF = true
      printFamilyNoticePdf(this.id).then((res) => {
        if (res.code === 0) {
          if (res.data.url) {
            printJS(res.data.url)
          }
        }
      })
      this.loadingPDF = false
    }
  }
}
</script>
